<template>
  <div class="my-account">
    <div class="header-menu va-row">
      <div class="flex md10 offset--md1">
        <div class="body-spacing">
          <div class="header-teacher">
            <div class="va-row">
              <div class="flex xs6 ddh-item">
                Dentro Educa – {{ teacherCredential.user.client_name }}
              </div>
              <div class="flex xs6 ddh-prof">
                <router-link class :to="{ name: 'main' }">
                  Encerrar sessão
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.2373 19.5721C13.625 20.1843 13.6415 21.1843 14.2373 21.7801C14.8478 22.3907 15.8348 22.3907 16.4453 21.7801L21.1279 17.0975C21.7385 16.4869 21.7385 15.5 21.1279 14.8895L16.4453 10.2068C15.8348 9.59627 14.8478 9.59627 14.2373 10.2068C13.6268 10.8173 13.627 11.8043 14.2373 12.4149L16.2465 14.4348H5.56522C4.70375 14.4348 4 15.1385 4 16C4 16.8615 4.70375 17.5652 5.56522 17.5652H16.2441L14.2373 19.5721Z"
                      fill="#FFFFFF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M25.1304 4H6.86957C5.27682 4 4 5.29156 4 6.86957V8.78261C4 9.64408 4.70375 10.3478 5.56522 10.3478C6.42668 10.3478 7.13044 9.64408 7.13044 8.78261V8.17391C7.13044 7.6006 7.6006 7.13044 8.17391 7.13044H23.8261C24.3994 7.13044 24.8696 7.6006 24.8696 8.17391V23.8261C24.8696 24.3994 24.3994 24.8696 23.8261 24.8696H8.17391C7.6006 24.8696 7.13044 24.3994 7.13044 23.8261V23.2174C7.13044 22.3559 6.42668 21.6522 5.56522 21.6522C4.70375 21.6522 4 22.3559 4 23.2174V25.1304C4 26.7093 5.29071 28 6.86957 28H25.1304C26.7093 28 28 26.7093 28 25.1304V6.86957C28 5.29071 26.7093 4 25.1304 4Z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </router-link>

                <img
                  :src="teacherCredential.user.client_logo"
                  class="logo-client"
                  alt="logo"
                  width="120"
                />
              </div>
            </div>

            <div class="va-row">
              <div class="flex xs12 text-left school-name">
                <h4>
                  <!-- <router-link :to="{ name: 'grades' }">
										<span class="back-circle">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="36"
												height="36"
												viewBox="0 0 36 36"
											>
												<path
													fill="#AFA2BA"
													fill-rule="evenodd"
													stroke="#AFA2BA"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="1.6"
													d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.004 1.004 0 0 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"
												/>
											</svg>
										</span>
									</router-link> -->
                  <!-- {{ teacherCredential.name }} -->
                  {{ teacherCredential.name }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="teacher-grade va-row" v-if="teacherCredential">
        <div class="flex md10 offset--md1 xs12 menu-list">
          <div class="body-spacing">
            <div class="va-row">
              <div class="flex md8 xs12 no-padding">
                <menu-tabs
                  :items="[
                    { label: 'Minhas turmas', slug: 'grades', isActive: true },
                    {
                      label: 'Livros personalizados',
                      slug: 'infobook',
                      isActive: false,
                    },
                    {
                      label: 'Material complementar',
                      slug: 'extra',
                      isActive: false,
                    },
                  ]"
                  @scrollTo="scrollParent"
                  ref="tabs"
                  class="container-ddh-menu"
                ></menu-tabs>
              </div>

              <div class="flex md4 xs12 text-right no-padding">
                <router-link
                  class="btn btn-primary"
                  target="_blank"
                  :to="{ name: 'LoginStudent' }"
                >
                  ÁREA DO ALUNO
                  <!-- <span class="fa fa-external-link"></span> -->
                  <svg
                    width="24"
                    height="24"
                    class="fa svg"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.3333 24C25.3333 24.7333 24.7333 25.3333 24 25.3333H8C7.26667 25.3333 6.66667 24.7333 6.66667 24V8C6.66667 7.26667 7.26667 6.66667 8 6.66667H12.6667C13.4 6.66667 14 6.06667 14 5.33333C14 4.6 13.4 4 12.6667 4H6.66667C5.18667 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V19.3333C28 18.6 27.4 18 26.6667 18C25.9333 18 25.3333 18.6 25.3333 19.3333V24Z"
                      fill="#FFFFFF"
                    />
                    <path
                      d="M17.7767 5.83333C17.7767 4.82386 18.6005 4 19.61 4H26.2767C27.2861 4 28.11 4.82386 28.11 5.83333V12.5C28.11 13.5095 27.2861 14.3333 26.2767 14.3333C25.2672 14.3333 24.4433 13.5095 24.4433 12.5V10.2538L13.1236 21.5736C12.4083 22.2888 11.2517 22.2888 10.5364 21.5736C9.82118 20.8583 9.82118 19.7017 10.5364 18.9864L21.8562 7.66667H19.61C18.6005 7.66667 17.7767 6.84281 17.7767 5.83333Z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="header-menu va-row">
			<div class="flex md10 offset--md1">
				<div class="body-spacing">
					<div class="header-teacher">
						<div class="va-row">
							<div class="flex xs6 ddh-item">
								Dentro Educa é desenvolvido por: Dentro da História
							</div>
							<div id="btn-closing-session" class="flex xs6 ddh-prof">
								<router-link class="" :to="{ name: 'main' }">
									Encerrar sessão
									<i class="fa fa-sign-out" aria-hidden="true"></i>
								</router-link>

								<img
									:src="teacherCredential.user.client_logo"
									class="logo-client"
									alt="logo"
									width="120"
								/>
							</div>
						</div>

						<div class="va-row">
							<div class="flex xs12 text-left school-name">
								<h4>{{ teacherCredential.name }}</h4>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="teacher-grade va-row">
				<div class="flex md10 offset--md1 no-padding-bottom">
					<div class="body-spacing">
						<div class="va-row">
							<div class="flex md8 xs12 no-padding">
								<menu-tabs
									:items="[
										{ label: 'Minhas turmas', slug: 'grades', isActive: true },
										{
											label: 'Livros personalizados',
											slug: 'infobook',
											isActive: false,
										},
										{
											label: 'Material complementar',
											slug: 'extra',
											isActive: false,
										},
									]"
									@scrollTo="scrollParent"
									ref="tabs"
									class="container-ddh-menu"
								></menu-tabs>
							</div>

							<div class="flex md4 xs12 text-right no-padding">
								<router-link
									class="btn btn-primary"
									target="_blank"
									:to="{ name: 'LoginStudent' }"
								>
									ÁREA DO ALUNO <span class="fa fa-external-link"></span>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->

    <div class="body-teacher">
      <div class="va-row">
        <div class="flex md10 offset--md1">
          <div class="body-spacing">
            <!-- {{list}} -->

            <div class="panel" v-for="(grade, id) in list" :key="id">
              <div class="va-row">
                <div class="flex md6 text-left">
                  <h4>
                    {{ grade.school.toUpperCase() }}
                  </h4>
                </div>
                <div class="flex md6 text-right">
                  <h4>
                    {{ grade.name.toUpperCase() }}
                  </h4>
                </div>
              </div>

              <div class="tab-teacher">
                Cada um da turma receberá
                {{ grade.books_per_student }}
                {{ grade.books_per_student > 1 ? "livros" : "livro" }}.
              </div>

              <div class="va-row">
                <div
                  class="flex xs12 no-padding"
                  :class="grade.teacher_count > 0 ? 'md8' : 'md6'"
                >
                  <div class="va-row">
                    <div
                      class="flex box-padding-aluno"
                      :class="grade.teacher_count > 0 ? 'md6' : 'md12'"
                    >
                      <div class="box-itens" :class="grade.grade_status_color">
                        <div class="margin-name-box">
                          <span class="name-box">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.85 14.425C8.46667 14.425 8.14583 14.2958 7.8875 14.0375C7.62917 13.7792 7.5 13.4583 7.5 13.075C7.5 12.6917 7.62917 12.3708 7.8875 12.1125C8.14583 11.8542 8.46667 11.725 8.85 11.725C9.23333 11.725 9.55417 11.8542 9.8125 12.1125C10.0708 12.3708 10.2 12.6917 10.2 13.075C10.2 13.4583 10.0708 13.7792 9.8125 14.0375C9.55417 14.2958 9.23333 14.425 8.85 14.425ZM15.175 14.425C14.7917 14.425 14.4708 14.2958 14.2125 14.0375C13.9542 13.7792 13.825 13.4583 13.825 13.075C13.825 12.6917 13.9542 12.3708 14.2125 12.1125C14.4708 11.8542 14.7917 11.725 15.175 11.725C15.5583 11.725 15.8792 11.8542 16.1375 12.1125C16.3958 12.3708 16.525 12.6917 16.525 13.075C16.525 13.4583 16.3958 13.7792 16.1375 14.0375C15.8792 14.2958 15.5583 14.425 15.175 14.425ZM12 20.5C14.3667 20.5 16.375 19.675 18.025 18.025C19.675 16.375 20.5 14.3667 20.5 12C20.5 11.5667 20.4667 11.1417 20.4 10.725C20.3333 10.3083 20.25 9.925 20.15 9.575C19.8167 9.65833 19.4542 9.71667 19.0625 9.75C18.6708 9.78333 18.2667 9.8 17.85 9.8C16.2333 9.8 14.7042 9.46667 13.2625 8.8C11.8208 8.13333 10.5917 7.18333 9.575 5.95C9.00833 7.3 8.19583 8.47917 7.1375 9.4875C6.07917 10.4958 4.86667 11.275 3.5 11.825V12C3.5 14.3667 4.325 16.375 5.975 18.025C7.625 19.675 9.63333 20.5 12 20.5ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"
                                fill="white"
                              />
                            </svg>
                            Alunos na turma</span
                          >
                        </div>
                        <div class="background-description">
                          Total de alunos com livros criados
                          <span class="quantity-description">{{
                            grade.students_complete_count
                          }}</span>
                        </div>

                        <div class="background-description">
                          Total de alunos na turma
                          <span class="quantity-description">{{
                            grade.books_count
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="flex md6" v-if="grade.teacher_count > 0">
                      <div class="box-itens" :class="grade.grade_status_color">
                        <div class="margin-name-box">
                          <span class="name-box">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M22.5 17.4997V16.6663C22.5 16.114 22.0523 15.6663 21.5 15.6663H16.95C17.0417 15.5747 17.0875 15.5288 17.1792 15.4372C18.3708 14.1538 19.15 12.5497 19.2875 11.1288C19.425 9.708 19.0125 8.33301 18.0958 7.23301C17.1792 6.13301 15.8958 5.44551 14.5208 5.30801C14.0646 5.26999 13.6399 5.29505 13.1945 5.35701C13.0522 5.3768 12.9248 5.26179 12.9425 5.11927C13.0486 4.26459 13.3749 3.97128 14.0847 3.87608C14.5864 3.80877 15 3.42293 15 2.91667V2.91667C15 2.41041 14.5874 1.9904 14.0848 2.05125C12.2514 2.27324 11.2277 3.23276 11.0935 5.24849C11.0866 5.35262 10.9922 5.43014 10.8889 5.41483C10.4128 5.34424 9.96377 5.31346 9.47917 5.35385C8.05833 5.49135 6.775 6.17885 5.90417 7.27885C5.03333 8.37885 4.575 9.708 4.7125 11.1288C4.85 12.5038 5.62917 14.108 6.82083 15.4372L7.05 15.6663H3.75C2.2375 15.6663 1 16.9039 1 18.4164V20.2497C1 21.7622 2.2375 22.9997 3.75 22.9997H21.5C22.0523 22.9997 22.5 22.552 22.5 21.9997V21.1664C21.4917 21.1664 20.6667 20.3414 20.6667 19.333C20.6667 18.3247 21.4917 17.4997 22.5 17.4997ZM19.4708 20.2497C19.5625 20.5705 19.8625 21.2247 20 21.4997H3.5C2.94772 21.4997 2.5 21.052 2.5 20.4997V17.9997C2.5 17.4474 2.94772 16.9997 3.5 16.9997H20C19.8167 17.2747 19.5625 18.0955 19.4708 18.4164H15.6667C15.1604 18.4164 14.75 18.8268 14.75 19.333V19.333C14.75 19.8393 15.1604 20.2497 15.6667 20.2497H19.4708Z"
                                fill="white"
                              />
                            </svg>
                            Professor</span
                          >
                        </div>
                        <div class="background-description">
                          Total de professor com livro criado
                          <span class="quantity-description">{{
                            grade.teacher_complete_count
                          }}</span>
                        </div>

                        <div class="background-description">
                          Professor da Turma
                          <span class="quantity-description">{{
                            grade.teacher_count
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 1 - Status 20 azul -->
                <div
                  class="flex box-padding-lembrete"
                  :class="grade.teacher_count > 0 ? 'md4' : 'md6'"
                >
                  <div
                    id="info-box"
                    class="info-box is-running"
                    v-if="
                      parseInt(grade.status_id) == 20 &&
                      grade.teacher_approval_status === false &&
                      grade.teacher_approval_delay_class !== 'yellow' &&
                      grade.teacher_approval_delay_class !== 'red'
                    "
                  >
                    <div class="name-box-lembrete">Lembrete:</div>
                    <span class="name-box-lembrete-description">
                      Você tem até
                      {{
                        formatarDataParaExtensoComNumero(grade.teacher_approval)
                      }}
                      para criar todos os livros com seus alunos.</span
                    >

                    <div class="main-date">
                      {{
                        formatarDataParaExtensoComNumero(grade.teacher_approval)
                      }}
                    </div>
                  </div>

                  <div
                    id="info-box"
                    class="info-box is-running danger"
                    v-else-if="
                      parseInt(grade.status_id) == 20 &&
                      grade.teacher_approval_status === false &&
                      grade.teacher_approval_delay_class === 'red'
                    "
                  >
                    <div class="name-box-lembrete">Atenção:</div>
                    <br />
                    <span class="name-box-lembrete-description-red"
                      >Para finalizar, acesse sua turma e clique no botão enviar
                      para impressão localizado no final da página.</span
                    >

                    <div class="main-date">
                      {{
                        formatarDataParaExtensoComNumero(grade.teacher_approval)
                      }}
                    </div>
                  </div>

                  <div
                    id="info-box"
                    class="info-box is-running success"
                    v-else-if="
                      grade.status_id == 20 &&
                      grade.teacher_approval &&
                      grade.teacher_approval_status == true &&
                      grade.teacher_approval_realized_day &&
                      grade.teacher_approval_delay_class != 'red' &&
                      grade.teacher_approval_delay_class != 'yellow'
                    "
                  >
                    <div class="name-box-parabens">Parabéns:</div>
                    <br />
                    <span class="name-box-parabens-description"
                      >Tudo ocorreu dentro do estimado graças a você! Agora é só
                      relaxar e aguardar os livros de seus alunos
                      chegarem.</span
                    >
                  </div>

                  <!-- <div
										id="info-box"
										class="info-box is-running success"
										v-if="
											grade.teacher_approval &&
											grade.teacher_approval_status == true &&
											grade.teacher_approval_realized_day &&
											(grade.teacher_approval_delay_class == 'red' ||
												grade.teacher_approval_delay_class == 'yellow')
										"
									>
										<b>Parabéns:</b><br />
										Os livros foram enviados para impressão. Agora é só relaxar
										e aguardar os livros de seus alunos chegarem.
									</div> -->

                  <div
                    id="info-box"
                    class="info-box is-running warning"
                    v-if="
                      grade.status_id == 20 &&
                      grade.teacher_approval_delay_class == 'yellow'
                      //grade.teacher_approval_status == false
                    "
                  >
                    <div class="name-box-lembrete">Atenção:</div>
                    <span class="name-box-lembrete-description-red"
                      >Você tem poucos dias para enviar os livros de seus alunos
                      para a impressão.</span
                    >

                    <div class="main-date">
                      {{ formatarDataParaExtensoComNumero(grade.deadline) }}
                    </div>
                  </div>

                  <div
                    id="info-box"
                    class="info-box is-running success"
                    v-if="
                      grade.status_id == 21
                      //grade.teacher_approval_delay_class == 'yellow'
                      //grade.teacher_approval_status == false
                    "
                  >
                    <div class="name-box-parabens">Parabéns:</div>
                    <br />

                    <span class="name-box-parabens-description"
                      >Tudo ocorreu dentro do estimado graças a você! Agora é só
                      relaxar e aguardar os livros de seus alunos chegarem.
                    </span>

                    <div class="main-date">
                      <!-- {{ formatarDataParaExtensoComNumero(grade.deadline) }} -->
                    </div>
                  </div>

                  <div
                    id="info-box"
                    class="info-box is-running success"
                    v-if="
                      grade.status_id == 22 &&
                      grade.teacher_approval_delay_class == 'red'

                      //grade.teacher_approval_delay_class == 'yellow'
                      //grade.teacher_approval_status == false
                    "
                  >
                    <div class="name-box-parabens">Parabéns:</div>
                    <br />

                    <span class="name-box-parabens-description"
                      >Os livros foram enviados para impressão. Agora é só
                      relaxar e aguardar os livros de seus alunos
                      chegarem.</span
                    >

                    <div class="main-date">
                      <!-- {{ formatarDataParaExtensoComNumero(grade.deadline) }} -->
                    </div>
                  </div>

                  <div
                    id="info-box"
                    class="info-box is-running success"
                    v-if="
                      grade.status_id == 22 &&
                      grade.teacher_approval_delay_class != 'red'

                      //grade.teacher_approval_delay_class == 'yellow'
                      //grade.teacher_approval_status == false
                    "
                  >
                    <div class="name-box-parabens">Parabéns:</div>
                    <br />

                    <span class="name-box-parabens-description"
                      >Os livros foram enviados para impressão. Agora é só
                      relaxar e aguardar os livros de seus alunos
                      chegarem.</span
                    >

                    <div class="main-date">
                      <!-- {{ formatarDataParaExtensoComNumero(grade.deadline) }} -->
                    </div>
                  </div>

                  <!-- <div
										id="info-box"
										class="info-box is-running warning"
										v-if="grade.status_id == '21'"
									>
										<b>Atenção:</b><br />
										Os livros estão sendo enviados para impressão. Caso este
										status não mude para enviado em 24 horas entrar em contato
										com o consultor.
								

										<div class="main-date">{{ grade.teacher_approval }}</div>
									</div> -->
                </div>

                <div></div>
              </div>

              <hr />

              <div class="va-row footer-panel">
                <div
                  class="flex md7 date"
                  v-if="
                    grade.teacher_approval_status == false &&
                    grade.teacher_approval_delay_class !== 'red' &&
                    grade.teacher_approval_delay_class !== 'yellow' &&
                    grade.status_id != 21
                  "
                >
                  <div>
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_671_20790)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.69628 3.96429C6.69628 3.01751 7.49577 2.25 8.48199 2.25C9.46822 2.25 10.2677 3.01751 10.2677 3.96429V4.82144H14.7322V3.96429C14.7322 3.01751 15.5317 2.25 16.5179 2.25C17.5041 2.25 18.3036 3.01751 18.3036 3.96429V4.82144H19.7917C20.9423 4.82144 21.875 5.71687 21.875 6.82144V19.75C21.875 20.8546 20.9423 21.75 19.7917 21.75H5.20833C4.05774 21.75 3.125 20.8546 3.125 19.75V6.82144C3.125 5.71687 4.05774 4.82144 5.20833 4.82144H6.69628V3.96429ZM4.91076 10.8215V19.0213C4.91076 19.5736 5.37713 20.0213 5.95243 20.0213H19.0477C19.623 20.0213 20.0893 19.5736 20.0893 19.0213V10.8215H4.91076Z"
                          fill="#4965BC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_671_20790">
                          <rect width="25" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <b
                      >Faltam
                      {{ formatarDeDiferencaDeDias(grade.teacher_approval) }}
                      dias.</b
                    >
                  </div>
                </div>

                <div
                  class="flex md7 date date-yellow"
                  v-if="
                    grade.status_id == 20 &&
                    grade.teacher_approval_delay_class == 'yellow'
                  "
                >
                  <div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_671_20821)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M19.7027 20.25H4.29728C2.72195 20.25 1.73993 18.5562 2.5276 17.2072L10.2303 4.01172C11.018 2.66276 12.982 2.66276 13.7697 4.01172L21.4724 17.2072C22.2601 18.5562 21.278 20.25 19.7027 20.25ZM13.7063 17.1506C13.7063 16.2244 12.9423 15.4735 12 15.4735C11.0577 15.4735 10.2938 16.2244 10.2938 17.1506C10.2938 18.0768 11.0577 18.8277 12 18.8277C12.9423 18.8277 13.7063 18.0768 13.7063 17.1506ZM13.5512 8.80079C13.5512 7.95876 12.8567 7.27617 12 7.27617C11.1434 7.27617 10.4489 7.95877 10.4489 8.80079V12.7132C10.4489 13.5552 11.1434 14.2378 12 14.2378C12.8567 14.2378 13.5512 13.5552 13.5512 12.7132V8.80079Z"
                          fill="#FEAF00"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_671_20821">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span
                      v-if="
                        formatarDeDiferencaDeDias(grade.teacher_approval) > 1
                      "
                    >
                      Faltam
                      {{ formatarDeDiferencaDeDias(grade.teacher_approval) }}
                      dias.</span
                    >

                    <span
                      v-if="
                        formatarDeDiferencaDeDias(grade.teacher_approval) == 1
                      "
                    >
                      Falta
                      {{ formatarDeDiferencaDeDias(grade.teacher_approval) }}
                      dia.</span
                    >

                    <span
                      v-if="
                        formatarDeDiferencaDeDias(grade.teacher_approval) == 0
                      "
                    >
                      Hoje termina o prazo.
                    </span>

                    {{
                      formatarDeDiferencaDeDias(grade.teacher_approval) > 0
                        ? "Falta pouco para o prazo vencer."
                        : ""
                    }}
                  </div>
                </div>

                <div
                  class="flex md7 date date-red"
                  v-if="
                    grade.status_id == 20 &&
                    grade.teacher_approval_delay_class == 'red'
                  "
                >
                  <div>
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.2402 3.21609L19.3385 3.10124C19.8219 3.51634 19.8943 4.24633 19.4762 4.7357C19.0601 5.23297 18.3312 5.29106 17.8451 4.88519L14.7534 2.30237C14.2699 1.88721 14.1982 1.15764 14.605 0.668583C15.0211 0.170692 15.7503 0.112333 16.2367 0.51839L19.3368 3.09976L19.2402 3.21609Z"
                        fill="#FF4641"
                      />
                      <path
                        d="M0.523087 4.73491C0.930132 5.23422 1.65972 5.29019 2.14481 4.88522L5.23522 2.3035C5.72974 1.88873 5.80263 1.1584 5.38504 0.668705C4.978 0.169384 4.2484 0.113411 3.76331 0.51839L0.663233 3.09975L0.661494 3.10124C0.178348 3.51612 0.105774 4.24554 0.523087 4.73491Z"
                        fill="#FF4641"
                      />
                      <path
                        d="M13.0841 14.9228C13.4934 15.1641 14.0305 15.0467 14.2769 14.6277C14.5293 14.2055 14.3992 13.657 13.9714 13.4114L13.9703 13.4107L10.6543 11.4391V6.95724C10.6543 6.47034 10.2646 6.07993 9.77856 6.07993H9.71816C9.23215 6.07993 8.84245 6.47034 8.84245 6.95724V11.7169C8.84245 12.1211 9.04999 12.5045 9.40987 12.7143L9.41084 12.7149L13.0829 14.9222L13.0841 14.9228Z"
                        fill="#FF4641"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10 2.04635C4.914 2.04635 0.789951 6.1779 0.789951 11.2732C0.789951 16.3684 4.914 20.5 10 20.5C15.086 20.5 19.2101 16.3684 19.2101 11.2732C19.2101 6.1779 15.086 2.04635 10 2.04635ZM10 18.1807C6.19806 18.1807 3.10505 15.082 3.10505 11.2732C3.10505 7.4643 6.19806 4.36566 10 4.36566C13.8019 4.36566 16.895 7.4643 16.895 11.2732C16.895 15.082 13.8019 18.1807 10 18.1807Z"
                        fill="#FF4641"
                      />
                    </svg>

                    <span
                      >Em atraso:
                      {{
                        formatarDeDiferencaDeDias(grade.teacher_approval)
                          .toString()
                          .replace("-", "")
                      }}
                      dias atrás.</span
                    >
                    Finalize a turma e evite atraso no cronograma.
                  </div>
                </div>

                <div
                  class="flex md7 date date-green"
                  v-if="grade.status_id == 21"
                >
                  <div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.8832 12.3468L8.99983 15.4634L18.5261 5.94676C19.1114 5.3615 20.0579 5.3615 20.6432 5.94676C21.2285 6.53202 21.2285 7.4786 20.6432 8.06387L10.0632 18.6439C9.47794 19.2291 8.53136 19.2291 7.94609 18.6439L3.7661 14.4639C3.18083 13.8786 3.18083 12.932 3.7661 12.3468C4.35136 11.7615 5.29794 11.7615 5.8832 12.3468Z"
                        fill="#00CF72"
                      />
                    </svg>

                    <span>Enviado para impressão.</span>
                  </div>
                </div>

                <div
                  class="flex md7 date date-green"
                  v-if="grade.status == 'enviado para impressão'"
                >
                  <div v-if="grade.deadline && grade.delivery">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.8832 12.3468L8.99983 15.4634L18.5261 5.94676C19.1114 5.3615 20.0579 5.3615 20.6432 5.94676C21.2285 6.53202 21.2285 7.4786 20.6432 8.06387L10.0632 18.6439C9.47794 19.2291 8.53136 19.2291 7.94609 18.6439L3.7661 14.4639C3.18083 13.8786 3.18083 12.932 3.7661 12.3468C4.35136 11.7615 5.29794 11.7615 5.8832 12.3468Z"
                        fill="#00CF72"
                      />
                    </svg>

                    <span>Enviado para impressão.</span>
                    <!-- Prazo previsto
										{{ formatarDataParaExtensoComNumero(grade.deadline) }}.
										Enviado
										{{ formatarDataParaExtensoComNumero(grade.delivery) }}. -->
                  </div>
                </div>

                <div class="flex md5 action text-right">
                  <button
                    class="btn btn-send"
                    v-if="
                      grade.status_id == '20' &&
                      grade.students_books_to_be_made ==
                        grade.books_complete_count
                    "
                    @click="showModal(grade)"
                  >
                    ENVIAR PARA IMPRESSÃO
                  </button>
                  <router-link
                    id="btn-class-access"
                    class="btn"
                    :to="{ name: 'grade', params: { grade: grade } }"
                  >
                    ACESSAR TURMA

                    <div
                      class="flag"
                      v-if="showPopUp && grade.books_completed === 0"
                      @mouseleave="hidePopUp()"
                    >
                      <div class="va-row">
                        <div class="flex xs2 no-padding icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            viewBox="0 0 36 36"
                          >
                            <path
                              fill="#fff"
                              fill-rule="evenodd"
                              d="M13.73 7h9.54L30 13.73v9.54L23.27 30h-9.54L7 23.27v-9.54L13.73 7zm5.807 17.35a1.47 1.47 0 0 0 .432-1.038 1.471 1.471 0 0 0-2.51-1.041 1.48 1.48 0 0 0-.428 1.04c0 .386.155.765.429 1.038.276.274.655.432 1.04.432s.764-.158 1.037-.432zM17.35 13.157v6.859a1.15 1.15 0 1 0 2.3 0v-6.859a1.15 1.15 0 1 0-2.3 0z"
                            />
                          </svg>
                        </div>
                        <div class="flex xs10">
                          <b>Cadastre os alunos</b>
                          <p>
                            Agora você precisar cadastrar os seus alunos e
                            depois dar andamento dentro de sala de aula com a
                            lista de atividades.
                          </p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>

                <!-- <div class="flex md6">
									<span class="yellow">Faltam 20 dias.</span> Falta pouco para o
									prazo vencer.
								</div> -->
              </div>
            </div>

            <div class="va-row questions" v-if="faq.length">
              <div class="flex xs12">
                <h1>Perguntas frequentes</h1>
                <div>
                  <vuestic-tree-root ref="treeView">
                    <vuestic-tree-category
                      v-for="(question, id) in faq"
                      :key="id"
                    >
                      <div class="va-row" slot="content-line">
                        <div class="flex xs12 no-padding">
                          {{ question.question }}
                        </div>
                      </div>
                      <vuestic-tree-node>
                        <div class="va-row">
                          <div
                            class="flex xs12 no-padding"
                            v-html="question.answer"
                          ></div>
                        </div>
                      </vuestic-tree-node>
                    </vuestic-tree-category>
                  </vuestic-tree-root>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vuestic-modal
      :show.sync="show"
      v-bind:large="true"
      ref="largeModal"
      :okText="'modal.confirm' | translate"
      :cancelText="'modal.cancel' | translate"
      :noButtons="true"
    >
      <div class="flex xs12 text-center">
        <h1>
          Tem certeza de que os livros
          <br />estão todos certinhos?
        </h1>
        <p>
          A partir de agora não será possível fazer
          <br />alterações e logo eles serão impressos!
        </p>

        <img
          :src="require('@/assets/images/impressora.png')"
          alt
          class="congrats"
        />
        <br />
        <br />

        <div class="flex xs12 no-padding-right text-center">
          <button
            type="button"
            class="btn btn-primary cancel"
            v-on:click="closeModal()"
          >
            NÃO, QUERO REVISAR OS LIVROS.
          </button>
          &nbsp;
          <button @click="finish()" class="btn btn-primary">
            SIM, QUERO FINALIZAR!
          </button>
        </div>
      </div>
    </vuestic-modal>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import VuesticDataTable from "../../vuestic-theme/vuestic-components/vuestic-datatable/VuesticDataTable.vue";

const axios = require("axios");

export default {
  name: "grades",
  directives: { mask },
  // components: { VuesticTreeCategory, VuesticTreeNode, VuesticTreeRoot },
  computed: {
    ...mapGetters({
      teacherCredential: "teacherCredential",
      // ...
    }),
  },
  data() {
    return {
      showPopUp: false,
      list: null,
      schoolInfo: null,
      gradeInfo: null,
      selectedGrade: null,
      emptySchool: {
        name: "",
        address: {
          district: "",
          address_number: "",
          state: "",
          city: "",
          address1: "",
          address2: "",
          zip_postal_code: "",
        },
        contact_name: "",
        contact_email: "",
        contact_phone: "",
        type: 0,
        project_id: 0,
      },
      emptyGrade: {
        role_id: "20",
        project_id: "1",
        name: "",
        grade_type_id: "",
        period_id: "",
        year: "",
        school_id: "",
        size: "",
        deadline: "",
      },
      schoolModalType: "view",
      schoolSelected: null,
      gradeModalType: "view",
      gradeSelected: null,
      files: "",
      show: false,
      // questions: [
      //     {
      //         question: "Pergunta",
      //         answer: "Resposta"
      //     },
      // ]
      faq: [],
    };
  },
  mounted() {
    this.initMounted();
  },

  methods: {
    initMounted() {
      const self = this;
      let config = {
        headers: {
          Authorization: "Bearer " + this.teacherCredential.token,
        },
      };
      axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/grades?page=1&limit=100&sort=name asc&active=true&school_id=" +
            this.teacherCredential.school_id,
          config
        )
        .then((response) => {
          self.list = response.data.data.grades;
        })
        .catch((e) => {
          this.errors.push(e);
        });
      axios
        .get(process.env.VUE_APP_ROOT_API + "/faq", config)
        .then((response) => {
          self.faq = response.data.data.faq;
        })
        .catch((e) => {
          this.errors.push(e);
        });
      var schoolId = this.teacherCredential.school_id;
      if (this.teacherCredential.school_id.length > 1) {
        schoolId = this.teacherCredential.school_id[0];
      }
    },

    formatarDeDiferencaDeDias(dataFinal) {
      // var data = $('#datepicker').datepicker('getDate');
      var extenso;
      //const str = '22/04/2022';
      // Data inicial
      // var [day, month, year] = dataToFormat.split("/");
      // var data = new Date(+year, month - 1, +day);
      var data = new Date();
      // Data final
      var [day2, month2, year2] = dataFinal.split("/");
      var data2 = new Date(+year2, month2 - 1, +day2);
      //  var date1 = new Date(day +"=" + month + "/" + year);
      // var date2 = new Date("07/30/2019");
      // To calculate the time difference of two dates
      var Difference_In_Time = data2.getTime() - data.getTime();
      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return parseInt(Difference_In_Days);
      //$('#lblDataExtenso').html(`${day}, ${date} de ${month} de ${year}`);
    },
    formatarDataParaExtenso(dataToFormat) {
      // var data = $('#datepicker').datepicker('getDate');
      var extenso;
      //const str = '22/04/2022';
      var [day, month, year] = dataToFormat.split("/");
      var data = new Date(+year, month - 1, +day);
      //var data = new Date(dataToFormat.replace("/", "-"));
      var day = [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
      ][data.getDay()];
      var date = data.getDate();
      var month = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ][data.getMonth()];
      var year = data.getFullYear();
      //console.log(data);
      return `${day}, ${date} de ${month} de ${year}`;
      //$('#lblDataExtenso').html(`${day}, ${date} de ${month} de ${year}`);
    },
    formatarDataParaExtensoComNumero(dataToFormat) {
      // var data = $('#datepicker').datepicker('getDate');
      var extenso;
      //const str = '22/04/2022';
      var [day, month, year] = dataToFormat.split("/");
      var data = new Date(+year, month - 1, +day);
      //var data = new Date(dataToFormat.replace("/", "-"));
      // var day = [
      // 	"Domingo",
      // 	"Segunda-feira",
      // 	"Terça-feira",
      // 	"Quarta-feira",
      // 	"Quinta-feira",
      // 	"Sexta-feira",
      // 	"Sábado",
      // ][data.getDay()];
      var date = data.getDate();
      var month = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ][data.getMonth()];
      var year = data.getFullYear();
      //console.log(data);
      return `${date} de ${month}`;
      //$('#lblDataExtenso').html(`${day}, ${date} de ${month} de ${year}`);
    },
    scrollParent(slug) {
      this.$router.push({ name: slug });
    },
    showSchool(school, type) {
      this.schoolModalType = type;
      this.schoolSelected = school;
      this.$refs.largeModal.open();
    },
    showGrade(grade, school, type) {
      this.gradeModalType = type;
      this.gradeSelected = grade;
      this.schoolSelected = school;
      this.$refs.gradeModal.open();
    },
    closeModal() {
      this.$refs.largeModal.close();
      this.$refs.gradeModal.close();
    },
    clickOnFile() {
      this.$refs.files.click();
    },
    submitFiles() {
      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        formData.append("csv", file);
      }
      axios
        .post(process.env.VUE_APP_ROOT_API + "/schools/import", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + this.teacherCredential.token,
          },
        })
        .then(function () {
          alert("Upload feito com sucesso");
        })
        .catch(function () {
          alert("Erro, upload falhou");
        });
    },
    handleFileUpload() {
      this.files = this.$refs.files.files;
      this.submitFiles();
    },
    deleteSchool(id, key) {
      const self = this;
      let config = {
        headers: {
          Authorization: "Bearer " + this.teacherCredential.token,
        },
      };
      if (confirm("Você deseja excluir este item?")) {
        axios
          .delete(process.env.VUE_APP_ROOT_API + "/schools/" + id, config)
          .then((response) => {
            self.list.splice(key, 1);
            //self.$router.push({ name: 'schools' })
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    hidePopUp() {
      this.showPopUp = false;
    },
    showSubscriberEditing() {
      this.subscriberEditing = false;
    },
    hideSubscriberEditing() {
      this.subscriberEditing = false;
    },
    scrollParent(slug) {
      this.$router.push({ name: slug });
    },
    showModal(grade) {
      this.selectedGrade = grade;
      this.$refs.largeModal.open();
    },
    closeModal() {
      this.$refs.largeModal.close();
    },

    finish() {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + self.teacherCredential.token,
        },
      };

      axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/grades/print/" +
            self.selectedGrade.id,
          config
        )
        .then((response) => {
          self.initMounted();
          self.closeModal();
        })
        .catch((error) => {
          alert(error.response.data.message);
          self.closeModal();
        });
    },
  },

  components: { VuesticDataTable },
};
</script>
<style lang="scss" scoped>
::v-deep .vuestic-tree-category {
  border-top: 1px solid #dfdae4;
  padding-top: 15px !important;
  color: #68596e !important;
}

::v-deep .vuestic-tree-node-label {
  color: #68596e !important;
}

::v-deep .vuestic-tree-root {
  border-bottom: 0px;
}

.questions {
  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #68596e;
  }

  ::v-deep .square-with-icon {
    background-color: transparent;
    color: #4965bc;
    font-size: 35px;
  }
}
.info-box {
  border-radius: 10px;
  background-color: #f8f7fc;
  height: 100%;
  border-radius: 10px;
  background-color: #f8f7fc;
  padding: 16px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  &.success {
    background: #00cf72;
    color: white;
  }
}
.btn-white {
  position: relative;
  color: #4965bc;
  background-color: #fff;
  border: 0px;
  float: right;
}
.flag {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
  background-color: #4567ba;
  max-width: 310px;
  padding: 10px;
  width: 310px;
  position: absolute;
  color: #cbe1ff;
  font-size: 14px;
  font-family: "Nunito";
  border-radius: 10px;
  display: block;
  margin-left: -90px;
  text-align: left;
  font-weight: 200;
  bottom: 63px;

  p {
    margin-bottom: 0px !important;
  }

  .icon {
    align-items: center;
    display: flex;
  }

  b {
    color: #cbe1ff !important;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: -20px;
    width: 0px;
    height: 0px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #4567ba;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

::v-deep .box-itens {
  //  height: 215px;
  border-radius: 8px;
  background-color: #4965bc !important;
  padding: 16px;
  font-size: 16px;
  color: #ffffff;
  height: 100%;
  min-height: 180px;
  position: relative;

  .mini {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  @media (max-width: 990px) {
  }
  &.warning {
    background-color: rgb(254, 206, 0);

    .btn {
      color: rgb(254, 206, 0) !important;
    }
  }

  &.danger {
    background-color: rgb(255, 69, 71);

    .btn {
      color: rgb(255, 69, 71) !important;
    }
  }

  // &.success{
  //   background-color: rgb(254, 206, 0);
  //   .btn{
  //     color: rgb(254, 206, 0) !important;
  //   }
  // }

  // &.neutral{
  //   background-color: rgb(254, 206, 0);
  //   .btn{
  //     color: rgb(254, 206, 0) !important;
  //   }
  // }

  h1 {
    font-family: Nunito;
    font-size: 60px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    line-height: 1;
    margin-top: 50px;
    margin-bottom: 0px;
    position: absolute;
    bottom: 16px;
    span {
      font-size: 24px;
      position: relative;
      left: -16px;
    }
  }
}
::v-deep .vuestic-tabs .nav {
  padding-top: 0px !important;
}

::v-deep .vuestic-tabs {
  padding: 0px !important;
}

.phrase-secure {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #68596e;
  margin-top: 20px !important;
  // margin-bottom:20px !important;

  b {
    color: #68596e !important;
  }

  .btn {
    height: 65px;
    width: 100%;
    max-width: 357px;
    font-size: 14px;
    border-radius: 100px;
  }
}

.custom-teacher-table {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #afa2ba;

  b {
    font-size: 14px !important;
  }

  .header-table {
    height: 61.5px;
    background-color: #f8f7fc;
    border-top: solid 1px #dfdae4;
    line-height: 33px;
  }
}
.teacher-flag {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background-color: #9378f0;
  line-height: 30px;

  a {
    font-weight: bold;
    text-decoration: underline;
    color: white;
  }
}
.book-covers {
  justify-content: flex-end;
}
.empty-book {
  width: 100%;
  border: 1px solid #afa2ba;
  height: 100%;
  height: 187px;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  display: flex;
  font-size: 25px;
}
.btn-micro-edit {
  font-family: "Nunito";
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.67px;
  text-align: center;
  color: #4965bc;
  background: none;
  border: none;
}
.student-name {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #68596e;

  justify-content: center;
  flex-direction: column;
  text-align: left;
  display: flex;
}
.student-code {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 1.11px;
  text-align: right;
  color: #68596e;

  justify-content: center;
  flex-direction: column;
  text-align: right;
  display: flex;
}

.panel-gray {
  border-radius: 10px;
  border: solid 1px #dfdae4;
  background-color: #ffffff;
  overflow: hidden;

  .btn {
    border-radius: 100px;
    background-color: #4965bc;
    height: 65px;
    border-radius: 100px;
    background-color: #4965bc;
    padding-left: 70px;
    position: relative;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 40px;

    span {
      width: 45px;
      height: 45px;
      border-radius: 100px;
      background-color: #ffffff;
      font-size: 41px;
      display: inline-block;
      color: #4965bc;
      line-height: 43px;
      position: absolute;
      left: 8px;
      top: 8px;
    }
  }
}

.body-spacing {
  max-width: 1160px;
  margin: 0 auto;
}

.body-teacher {
  margin-top: 20px;

  .body-spacing .panel {
    padding: 16px;

    @media (max-width: 990px) {
    }

    h4 {
      margin-top: 10px;
      margin-bottom: 15px;
      font-family: Nunito;
      font-size: 20px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #66596e;
      margin-top: 0px;
      margin-bottom: 0px;
      text-transform: capitalize;
    }
  }

  hr {
    margin-bottom: 8px;

    &:last-child {
      margin-top: 0px;
    }
  }
}

.body-student {
  border-bottom: 1px solid #dfdae4;
}

.info-grade {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #68596e;
}

.school-name {
  font-family: "Nunito";
  font-size: 40px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 10px;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 20px;

  h4 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 0px;
  }

  span {
    font-size: 16px;
    font-weight: 200 !important;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .back-circle {
    width: 60px;
    height: 60px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: inline-block;
    text-align: center;
    border-radius: 30px;
  }
}
.my-account {
  position: absolute;
  top: 0px;
  background-color: #f8f7fc;
  z-index: 3;
  width: 100%;
  padding-bottom: 30px;
  min-height: 100%;
  overflow: hidden;
  // height: 100%;
}

.header-teacher {
  position: relative;

  .ddh-item {
    font-family: Nunito;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
  }

  .ddh-prof {
    font-family: Nunito;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;

    a {
      border: 0;
      background: none;
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #ffffff;
    }
  }
}

h4 {
  font-family: "Nunito";
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1.3px;
  color: #000000;
  margin-bottom: 0;
}

.header-menu {
  background-color: #00cf72;
  margin-bottom: 20px;
  display: block;

  .body-spacing {
    max-width: 1160px;
    margin: 0 auto;
  }

  .teacher-grade {
    background-color: white;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #68596e;
    box-shadow: 0 10px 25px 0 #e4d9ee;

    .menu-list {
      padding-bottom: 0px !important;
    }

    .btn {
      width: 275px;
      height: 45px;
      border-radius: 100px;
      // background-color: #9378f0;
      background-image: none;
      position: relative;
      line-height: 20px;
      top: 4px;

      @media (max-width: 767px) {
        display: block;
        margin: 0 auto;
        top: 15px;
      }

      &:hover {
        // background-color: #9378f0;
      }
      span {
        font-size: 17px;
        position: absolute;
        top: 15px;
        right: 23px;
      }

      svg {
        position: absolute;
        right: 20px;
        top: 10px;
      }
    }

    .teacher-name {
      line-height: 45px;
    }
  }

  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.7;
    letter-spacing: normal;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 990px) {
      font-size: 18px;
    }
  }

  .logo-client {
    position: absolute;
    bottom: 0px;
    display: block;
    right: 0px;
    margin: 0px;
    float: right;

    @media (max-width: 990px) {
      display: none;
    }
  }

  .school-name-short {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    color: #68596e;
    display: flex;
    align-items: center;
  }

  .school-name {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 10px;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 10px;
    margin-top: 10px;
    align-items: center;
    display: flex;

    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 10px;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 20px;

    h4 {
      font-family: "Nunito";
      font-size: 40px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-top: 0;
      display: flex;
      align-items: center;
      // line-height: 70px;
    }

    span {
      font-size: 16px;
      font-weight: 200 !important;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .back-circle {
      width: 60px;
      height: 60px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      // display: inline-block;
      text-align: center;
      border-radius: 30px;
      position: relative;
      float: left;
      margin-right: 10px;
      top: 0px;
      margin-top: 0px;
      margin-bottom: 0px;

      svg {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        margin: auto;
      }
    }
  }

  .header-teacher {
    position: relative;

    .ddh-item {
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff;
    }

    .ddh-prof {
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #ffffff;

      a {
        border: 0;
        background: none;
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #ffffff;
      }
    }
  }
}

.error-label {
  color: red;
  text-align: left;
  margin-bottom: 10px;
}

h5 {
  font-family: "Nunito";
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1.3px;
  color: #000000;
}

// .address-info{
//   margin-bottom:15px;
// }
// .editing{
//   margin-top:15px;
// }
// .radio-button-wrapper{
//   padding: 14px 11px;
//   margin-top: 10px;

//   @media(max-width: 990px){
//     padding: 20px 19px;
//     margin-top: 10px;
//   }
// }
.header-menu-club {
  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.7;
    letter-spacing: normal;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 990px) {
      font-size: 18px;
    }
  }
}

// Default of page

// .my-data{

//   /* Facebook bar */
//   .facebook-tag{
//     height: 65px;
//     border-radius: 5px;
//     background-color: #3a5998;
//     color:white;
//     padding: 14px 20px;
//     margin-bottom: 20px;

//     svg{
//       font-size: 26px;
//       margin-right: 0px;
//       float: left;

//       path{
//         fill: #fff;
//       }
//     }

//     .name{
//       display: inline-block;
//       margin-top: 1px;
//       line-height: 37px;
//     }

//     .facebook-toggle{
//       float: right;
//       display: block;
//       margin-top: 5px;
//     }
//   }

// }

// .change-password{
//   border-radius: 10px;
//   border: solid 2px #f0ebf5;
//   background-color: #ffffff;
//   padding: 24px 20px;
//   position: relative;

//   h4{
//     font-size: 16px;
//     color: #000000;
//     margin-top: 0px;
//     margin-bottom: 7px;
//     font-family: 'Roboto';

//     font-weight: normal;
//     font-style: normal;
//     font-stretch: normal;
//     letter-spacing: normal;
//   }

//   h5{
//     font-size: 14px;
//     font-weight: normal;
//     font-style: normal;
//     font-stretch: normal;
//     letter-spacing: normal;
//     color: #afa2ba;
//     margin-top: 0px;
//     margin-bottom: 0px;
//   }

//   .floated-icon{
//     float: left;
//     width: 36px;
//     height: 36px;
//     font-size:30px;

//     svg{
//       position: relative;
//       top: -5px;
//       left: -3px;

//       path{
//         fill: #afa2ba;
//       }
//     }
//   }

//   .new-password-group{
//     margin-top: 20px;
//     display: block;
//   }

//   .editor{
//     right: 18px;
//     top: 20%;
//   }

//   ::v-deep .form-group{
//     margin-bottom: 20px;
//     padding: 22px 20px;

//     ::v-deep &:last-child{
//       margin-bottom:20px;
//     }
//   }

// }

// .my-data-table{
//   h4{
//     margin-top: 50px;
//     margin-bottom: 20px;
//   }
// }

// .fiscal-notes{
//   border-radius: 10px;
//   border: solid 1px #f0ebf5;
//   background-color: #ffffff;
//   position: relative;
//   border: solid 2px #f0ebf5;

//   h4{
//     font-size: 16px;
//     color: #000000;
//     margin-top: 0px;
//     margin-bottom: 10px;
//     font-family: 'Roboto';
//     font-weight: bold;
//     font-style: normal;
//     font-stretch: normal;
//     line-height: normal;
//     letter-spacing: normal;
//   }

//   .body-spacing{
//     padding: 30px 30px 10px 30px;

//     @media(max-width:990px){
//       padding: 25px 15px 15px 15px;
//     }

//     //  div:last-child{
//     //   margin-bottom: 0px;
//     // }
//   }

//   hr{
//     border-top: solid 1px #f0ebf5;
//     margin-top: 0px;
//     margin-bottom: 0px;
//   }

//   .dual-inputs{
//     padding: 0px 8px;
//     margin-bottom: 20px;
//   }

//   .new-password-group{
//     margin-top: 20px;
//     display: block;
//   }

//   .editor{
//     right: 18px;
//     top: 20%;
//   }

//   .company-code{
//     text-transform: capitalize;
//   }

//   ::v-deep .form-group{
//     margin-bottom: 20px;
//     padding: 22px 20px;

//     ::v-deep &:last-child{
//       margin-bottom:20px;
//     }
//   }

//   // .panel:last-child{
//   //   margin-bottom: 0px;
//   // }
// }

// .address-boxes{
//   border-radius: 10px;
//   border: solid 1px #f0ebf5;
//   background-color: #ffffff;
//   position: relative;
//   border: solid 2px #f0ebf5;

//   h4{
//     font-size: 16px;
//     color: #000000;
//     margin-top: 0px;
//     margin-bottom: 10px;
//     font-family: 'Roboto';
//     font-weight: bold;
//     font-style: normal;
//     font-stretch: normal;
//     line-height: normal;
//     letter-spacing: normal;
//   }

//   svg{
//     path{
//       fill: #afa2ba;
//     }
//   }

//   .clube{
//     svg{
//     path{
//         fill: #fff;
//       }
//     }
//   }

//   .body-spacing{
//     padding: 30px 30px;

//     @media(max-width:990px){
//       padding: 25px 15px 15px 15px;
//     }

//   }

//   hr{
//     border-top: solid 2px #f0ebf5;
//   }

//   .dual-inputs{
//     padding: 0px 8px;
//     margin-bottom: 20px;
//   }

//   .new-password-group{
//     margin-top: 20px;
//     display: block;
//   }

//   .editor{
//     right: 18px;
//     top: 20%;
//   }

//   ::v-deep .form-group{
//     margin-bottom: 20px;
//     padding: 22px 20px;

//     ::v-deep &:last-child{
//       margin-bottom:20px;
//     }
//   }

//   .more-address{
//     border-radius: 10px;
//     border: solid 1px #f0ebf5;
//     background-color: #ffffff;
//   }

//   .address-row{
//     padding: 16px 0px;
//     position: relative;
//     border-bottom: solid 1px #f0ebf5;
//     b{
//       font-size: 16px;
//       color: #000000;
//     }
//     :last-child{
//       border-bottom: none;
//     }

//   }

// }

.address-row {
  position: relative;
}

.more-cards {
  .more-address {
    border-radius: 10px;
    border: solid 1px #f0ebf5;
    background-color: #ffffff;
  }
}

.panel {
  // height: 200px;
  border-radius: 10px;
  // border: solid 2px #9378f0;
  background-color: #ffffff;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0 10px 25px 0 #e2ddf6;
  background-color: #ffffff;
  padding: 8px 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .header-panel {
    padding: 0px 20px;
    background-color: #9378f0;
    line-height: 33px;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .footer-panel {
    margin-top: 16px !important;
    .date {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */
      padding: 0px !important;
      display: flex;
      align-items: center;

      b {
        /* color/neutral/darkest */

        color: #68596e;
      }

      svg {
        margin-right: 8px;
        margin-top: -5px;
      }

      span {
        font-weight: bold;
      }

      &.date-green {
        span {
          color: #00cf72;
        }
      }

      &.date-yellow {
        span {
          color: #feaf00;
        }
      }

      &.date-red {
        span {
          color: #ff4641;
        }
      }
    }

    .action {
      padding-bottom: 0px;
      padding-top: 0px;
    }
  }

  .clube img {
    margin-top: -5px;
  }

  b {
    font-size: 16px;
    color: #000000;
  }

  .panel-body {
    position: relative;
    padding: 10px 0px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #afa2ba;
    margin: 0px !important;

    .flex.xs1 {
      -ms-flex-preferred-size: 8.33333%;
      flex-basis: 6.33333%;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-width: 6.33333%;
    }

    b {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #000000;
    }

    .editor {
      right: 18px;
      top: 20%;
    }
  }
}

.subscription-box {
  // padding: 30px !important;

  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #000000;

  .toggled {
    margin-top: 10px;
  }
}
.newsletter {
  // margin-top:40px;
  padding: 31px;
  border: solid 2px #f0ebf5;

  @media (max-width: 990px) {
    margin-top: 0px;
    padding: 15px;
  }
}

// Custom Component
.v-switch-button {
  ::v-deep &:before {
    content: "";
    display: block;
    position: relative;
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 14.5px;
    margin-left: auto;
    margin-right: auto;
    top: 9px;
  }
}

.vue-js-switch {
  &.toggled {
    .v-switch-button {
      ::v-deep &:before {
        content: "";
        display: block;
        position: relative;
        background-color: #7ed321;
        width: 6px;
        height: 6px;
        border-radius: 14.5px;
        margin-left: auto;
        margin-right: auto;
        top: 9px;
      }
    }
  }
}

.name-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}
.lastname-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}

.logo-client {
  position: absolute;
  bottom: 0px;
  display: block;
  right: 0px;
  margin: 0px;
  float: right;

  @media (max-width: 990px) {
    display: none;
  }
}

.is-running {
  background: #3d549d;
  color: white;

  b {
    color: white;
  }
}

.main-date {
  position: absolute;
  bottom: 16px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
}

.warning {
  background-color: #feaf00;
}

.success {
  background: #00cf72;
}

.danger {
  background: #ff4641;
}
.tab-teacher {
  display: flex;
  align-items: center;
  background: #f4f2fb;
  border-radius: 8px;
  color: #68596e;
  padding: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.name-box {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}
.name-box-lembrete {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 1;
  color: #fff;
  margin-bottom: 16px;
  width: 100%;
}
.background-description {
  padding: 16px;
  background: #3d549d;
  border-radius: 8px;
  height: 60px;
  display: flex;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  justify-content: space-between;
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0px !important;
  }
}

.quantity-description {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 60px;
}
.margin-name-box {
  margin-bottom: 16px;
  align-items: center;
  display: flex;
}
.btn-send {
  margin-right: 8px;
  background: #00cf72;
  border-radius: 64px;
}
.box-padding-aluno {
  padding-left: 0px !important;
}
.box-padding-lembrete {
  padding-right: 0px !important;
}
.name-box-lembrete-description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.name-box-parabens {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 1;
  color: #fff;
  margin-bottom: 16px;
  width: 100%;
}
.name-box-parabens-description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  bottom: 10px;
}
.name-box-lembrete-description-red {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  position: relative;
}
</style>
